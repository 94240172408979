{
  "name": "Elevatix",
  "version": "1.0.199",
  "scripts": {
    "ng": "ng",
    "start": "ng serve",
    "build": "ng build  --source-map=true",
    "watch": "ng build --watch --configuration development",
    "test": "ng test",
    "sentry": "sentry-cli",
    "lint": "ng lint"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^17.2.4",
    "@angular/cdk": "^17.2.2",
    "@angular/common": "^17.2.4",
    "@angular/compiler": "^17.2.4",
    "@angular/core": "^17.2.4",
    "@angular/forms": "^17.2.4",
    "@angular/material": "^17.2.2",
    "@angular/platform-browser": "^17.2.4",
    "@angular/platform-browser-dynamic": "^17.2.4",
    "@angular/router": "^17.2.4",
    "@sentry/angular-ivy": "^7.84.0",
    "@sentry/cli": "^2.13.0",
    "@sentry/tracing": "^7.37.2",
    "d3": "^7.0.0",
    "date-fns": "^2.27.0",
    "i18n-iso-countries": "^7.6.0",
    "lodash": "^4.17.21",
    "rxjs": "~6.6.0",
    "tslib": "^2.1.0",
    "zone.js": "~0.14.2"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^17.2.3",
    "@angular-eslint/builder": "17.1.1",
    "@angular-eslint/eslint-plugin": "17.1.1",
    "@angular-eslint/eslint-plugin-template": "17.1.1",
    "@angular-eslint/schematics": "17.1.1",
    "@angular-eslint/template-parser": "17.1.1",
    "@angular/cli": "^17.2.3",
    "@angular/compiler-cli": "^17.2.4",
    "@jefiozie/ngx-aws-deploy": "^4.0.0",
    "@types/d3": "^7.0.0",
    "@types/jasmine": "~3.6.0",
    "@types/lodash": "^4.14.195",
    "@types/node": "^20.4.0",
    "@typescript-eslint/eslint-plugin": "5.62.0",
    "@typescript-eslint/parser": "5.62.0",
    "eslint": "^8.47.0",
    "jasmine-core": "~5.0.1",
    "karma": "~6.4.2",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.1",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "^2.1.0",
    "typescript": "~5.2.2"
  }
}
